
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from "react";
import Link from "next/link";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { fetchPairs } from "@/lib/fetchPairs";
import Image from "next/image";
const ErrorPage = ({ popularPairs, categories }) => {
    return (<>
            <Header />
            <div className="error padding-top padding-bottom bg-color-dark">
                <div className="container">
                    <div className="error__wrapper">
                        <div className="" data-aos="fade-up" data-aos-duration="800">
                            <div className="error__thumb text-center">
                                <Image src="/images/logo/instaswap_big_logos-white-error.png" alt="404 image" width={400} height={400} style={{ height: "400px" }}/>
                            </div>
                            <div className="error__content text-center">
                                <h2>
                                    <span>ooops!</span> page not found
                                </h2>
                                <p>
                                    {"Oops! It looks like you're lost. The page you were looking for couldn't be found. Don't worry, it happens to the best of us."}
                                </p>
                                <Link href="/" className="trk-btn trk-btn--border trk-btn--primary">
                                    Back to home
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Pass popularPairs to Footer */}
            <Footer pairsData={popularPairs} categories={categories}/>
        </>);
};
async function getStaticProps() {
    try {
        // Fetch popular pairs and blog categories concurrently
        const [popularPairs, categoriesRes] = await Promise.all([
            fetchPairs(),
            fetch("https://blog.instaswap.com/wp-json/wp/v2/categories")
        ]);
        if (!categoriesRes.ok) {
            throw new Error("Failed to fetch blog categories");
        }
        const categoriesData = await categoriesRes.json();
        // Filter out "uncategorized" and any category with a count of 0
        const filteredCategories = categoriesData.filter(cat => cat.slug !== 'uncategorized' && cat.count > 0);
        return {
            props: {
                popularPairs,
                categories: filteredCategories
            }
        };
    }
    catch (error) {
        console.error(error);
        return {
            props: {
                popularPairs: [],
                categories: []
            }
        };
    }
}
export default ErrorPage;

    async function __Next_Translate__getStaticProps__196120b07e9__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__196120b07e9__ as getStaticProps }
  